import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";
import React from 'react';
import Youtube from '../common/youtube';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      height: '200px'
    },
    story: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left'
    },
    action: {
        margin: theme.spacing(1),
    },
    centered: {
      maxWidth:'640px',
      margin:'auto'
    },
    overflow: {
      overflowY: 'auto',
      height: '100px'
    },
    confusion: {
      fontStyle: 'italic',
      color: 'red'
    },
    emphasis: {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    },
    link: {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  })
)

const THUMBNAILS_QUERY = graphql`
  query {
    thumbnails: allFile(filter: {relativeDirectory: {eq: "thumbnails"}}) {
      nodes {
        childImageSharp {
          fluid(quality: 100, fit: INSIDE) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }`

const TutorialSelector = ({rootPath, tutorials}:{rootPath:string, tutorials:any[]}) => {
  const { thumbnails } = useStaticQuery(THUMBNAILS_QUERY)
  const classes = useStyles()

  const tutorialCards = tutorials.map(tutorial => {
    const link = `${rootPath}/${tutorial.slug}`
    const thumbnail = thumbnails.nodes.find((t:any) => (t.childImageSharp.fluid.originalName.includes(tutorial.slug)))
    return (
      <Grid key={tutorial.slug} item xs={12} sm={6} md={3} style={{display: 'flex'}}>
        <Card style={{display: 'flex', justifyContent: 'normal', flexDirection: 'column'}}>
          {thumbnail && 
            <CardMedia title={tutorial.title} component={Link} to={link}>
              <Img className={classes.thumbnail} fluid={thumbnail.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }}/>
            </CardMedia>
          }
          {!thumbnail &&
            <CardActions>
              <div className={classes.centered}>
                <Button className={classes.action} variant="contained" color="secondary" size="small" component={Link} to={link}>Try it out!</Button>
              </div>
            </CardActions>
          }
          <CardContent>
            <Typography color="primary" gutterBottom>
              {tutorial.title}
            </Typography>
            <Typography variant="body1" component="p">
              {tutorial.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )}
  )

  return (
    <>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="h2">
              Tutorials
          </Typography>
          <Typography variant="body1" component="p">
              Here you will find a selection of wonderful tutorials for getting started with navigation and mapping
              or for learning how to take your applications to the next level.
          </Typography>
          <div className={classes.centered}>
            <Youtube embedId="DaoBGxt8iAs"/>
          </div>
        </CardContent>
      </Card>
      <Card className={classes.story}>
        <Grid container spacing={3} alignItems="stretch">
          {tutorialCards}
        </Grid>
      </Card>
    </>
  )
}

export default TutorialSelector