import { Box } from '@mui/material';
import React from "react";

import { graphql } from "gatsby";
import SEO from "../../components/common/seo";
import TutorialSelector from "../../components/tutorials/tutorial-selector";

const TutorialPage = ({data, location}:any) => {
  const params = new URLSearchParams(location.search)
  const editions = params.get("editions")?.split(",") || ["published"]
  const tags = params.get("tags")?.split(",") || ["icra"]
  const tutorials = data.allMarkdownRemark.nodes.map((node:any) => node.frontmatter)
  const filteredTutorials = tutorials.filter((tutorial:any) => {
    const tagMatched = tags.length < 1 || tags.some(tag => tutorial?.tags?.includes(tag))
    const editionMatched = editions.includes(tutorial?.edition)
    return tagMatched && editionMatched
  })
  return (
    <Box style={{ height: '95vh', overflowY: 'auto' }}>
      <Box>
      <SEO title="Tutorials" />
      <TutorialSelector rootPath="/get-started/tutorials" tutorials={filteredTutorials} />
      </Box>
    </Box>
  )
}

export default TutorialPage

export const pageQuery = graphql`
query MyQuery {
  allMarkdownRemark(sort: {order: ASC, fields: frontmatter___sort}) {
    nodes {
      frontmatter {
        description
        slug
        title
        edition
        tags
      }
    }
  }
}`